<template>
  <div id="resources">
    <Hero/>
    <div class="resources-section">
      <h1>Fly Fishing Resources</h1>
      <h3>Colorado Parks & Wildlife</h3> <p>Buy your fishing license <a target="_blank" :href="'https://cpw.state.co.us/'">here</a></p>
      <h3>Colorado Trout Fisher</h3> <p>Fish on public water with us! Book a guided trip <a id="to-ctf-resources" target="_blank" :href="'https://www.coloradotroutfisher.com/book-a-trip/'">here</a></p>
      <h3>Fly Fisher Guide Service</h3> <p>Fish on private water with us! Book a guided trip <a id="to-ffgs-resources" target="_blank" :href="'https://www.theflyfisher.com/'">here</a></p>
      <h3>Angling University</h3> <p>Learn to fly fish with online and in-person classes <a id="to-au-resources" target="_blank" :href="'https://www.anglinguniversity.com/'">here</a></p>
    </div>
    <FollowUs/>
    <BookNow/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import FollowUs from '@/components/FollowUs.vue'
import BookNow from '@/components/BookNow.vue'
export default {
  components: { 
    Hero, 
    FollowUs,
    BookNow
  }
}
</script>


<style lang="scss">
#resources {
  .resources-section {
    width: 65%;
    margin: auto;
    h1 {
      text-align: left;
      margin: 50px 0;
    }
    p, h3 {
      text-align: left;
    }
    p {
      margin-bottom: 50px;
    }
    a {
      color: #3C6E71;
      text-decoration: underline;
      font-weight: 600;
    }
  }
  @media only screen and (max-width: 500px) {
    .resources-section {
      width: 90%;
      margin: auto;
    }
  }
}
</style>